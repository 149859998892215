export const LANGUAGES = [
	{
		key: 'ITA',
		name: 'Italiano',
	},
	{
		key: 'ENG',
		name: 'English',
	},
	{
		key: 'FRA',
		name: 'French',
	},
	{
		key: 'ESP',
		name: 'Spanish',
	},
]